import React, { useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { get } from 'lodash';
import CartContext from '../context/CartProvider';
import Container from '../components/atoms/Container/Container';
import Layout from '../components/organisms/Layout/Layout';
import SEO from "../components/organisms/Seo/Seo"
import Cart from '../components/organisms/Cart/Cart';
import CartSummary from '../components/molecules/CartSummary/CartSummary';
import styles from './cart.module.css';

const CartIndexPage = () => {
  const ctx = useContext(CartContext);
  const numberItems = get(ctx, 'state.cart.numberItems');
  const cartLoading = typeof get(ctx, 'state.cartLoading') !== 'undefined' ? get(ctx, 'state.cartLoading') : true;

  return (
    <Layout>
      <SEO title="Cart" />
      <Container size="large">

        {cartLoading && (
          <div className={styles.bcCartContainer}>
            <div className={styles.bcCartTitle}>
              <h6 className="text-left">Cart</h6>
              <Link to="/shop/" className={styles.continueShopping}>
                continue shopping
              </Link>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className="text-center">
                <p className="subtitle text-center">Fetching your cart. Please wait...</p>
              </div>
            </div>
          </div>
        )}

        {(!cartLoading && !numberItems) && (
          <div className={styles.bcCartContainer}>
            <div className={styles.bcCartTitle}>
              <h6 className="text-left">Cart</h6>
              <Link to="/shop/" className={styles.continueShopping}>
                continue shopping
              </Link>
            </div>
            <div className={styles.bcCartEmpty}>
              <div className="text-center">
                <p className="subtitle text-center">Your cart is empty.</p>
                <Link to="/shop/" className={styles.bcCartLink}>
                  Take a look around.
                </Link>
              </div>
            </div>
          </div>
        )}

        {(!cartLoading && !!numberItems) && (
          <div className={clsx('grid-gap60 grid-7030', styles.bcCartContainer)}>
            <div>
              <div className={styles.bcCartTitle}>
                <h6 className="text-left">Cart</h6>
                <Link to="/shop/" className={styles.continueShopping}>
                  continue shopping
                </Link>
              </div>
              <Cart cartType="full" />
            </div>
            <CartSummary cartType="full" />
          </div>
        )}
      </Container>
    </Layout>
  );
};

export default CartIndexPage;
